<template>
  <div class="row">
    <h1 style="font-weight: 700">{{ $t("dashboard.overview") }}</h1>

    <div v-if="hasFace && hasFuel">
      <ul class="nav nav-tabs" id="dashboardTabs" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="fuel-tab"
            data-bs-toggle="tab"
            data-bs-target="#fuel"
            type="button"
            role="tab"
            aria-controls="fuel"
            aria-selected="true"
          >
            Fuel
          </button>
        </li>
        <li v-if="$can('faces.view')" class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="face-tab"
            data-bs-toggle="tab"
            data-bs-target="#face"
            type="button"
            role="tab"
            aria-controls="face"
            aria-selected="false"
          >
            Face
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="fuel"
          role="tabpanel"
          aria-labelledby="fuel-tab"
        >
          <standard
            :hasFace="hasFace"
          ></standard>
        </div>
        <div
          class="tab-pane fade"
          id="face"
          role="tabpanel"
          aria-labelledby="face-tab"
        >
          <face></face>
        </div>
      </div>
    </div>
    <div v-else-if="hasFace">
      <face></face>
    </div>
    <div v-else-if="hasFuel">
      <standard></standard>
    </div>
  </div>
  
</template>

<script>
import Standard from "./Customised/Standard.vue";
import Board from "./Customised/Board.vue";
import Face from "./Customised/Face.vue";
import clientService from "../../services/client.service";

export default {
  name: "Dashboard",
  components: {
    Standard,
    Board,
    Face,
  },
  data() {
    return {
      hasFace: false,
      hasFuel: false,
    };
  },
  mounted() {
    clientService
      .getClientSites(this.$store.state.auth.user.selectedClient, false)
      .then((response) => {
        this.hasFace = response.data.some((site) => site.hasFace);
        this.hasFuel = response.data.some((site) => site.hasFuel);
      });
  },
};
</script>

<style scoped>
.wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 50vh;
}
.nav-link {
  font-weight: 700;
  font-size: 1.2rem;
}
</style>
